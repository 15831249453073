<template>
  <el-dialog
    title="Change Gardener Status"
    :visible.sync="setShow"
    append-to-body
    width="30%"
  >
    <el-form :model="form" label-position="top" ref="form">
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item
            label="Select availability"
            prop="available"
            :rules="validateField()"
          >
            <el-radio-group v-model="form.available">
              <el-radio :label="true">Available</el-radio>
              <el-radio :label="false">Unavailable</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="closeEvent">Cancel</el-button>
      <el-button
        type="primary"
        :loading="updating"
        :disabled="disableChange"
        @click="changeStatus"
        >Change status</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import gardener from "@/requests/gardeners/gardener";

export default {
  name: "GardenerStatusChange",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    gardener: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        available: true,
      },
      updating: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    disableChange() {
      return this.form.available === !!this.gardener.available;
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.form.available = !!this.gardener.available;
      }
    },
  },
  methods: {
    closeEvent() {
      this.$refs.form.resetFields();
      this.setShow = false;
    },
    changeStatus() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.updating = true;

        gardener
          .availability(this.gardener.id, this.form)
          .then((response) => {
            if (response.data.status) {
              this.updating = false;
              this.$message.success({
                message: response.data.message,
                duration: 5000,
              });

              this.$emit("success", this.form.available);
              this.closeEvent();
            }
          })
          .catch((error) => {
            // const { data } = error.response;
            this.updating = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  font-size: 0.9rem;
  line-height: 1.8;
}
</style>
